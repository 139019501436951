<template>
  <section id="contents">
    <b-table-simple
      ref="refContentListTable"
      show-empty
      hover
      selectable
      :select-mode="single"
      :empty-text="$t('emptyText')"
      responsive
      primary-key="id"
      :no-border-collapse="true"
      style="height:500px"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="i in tableColumns"
            :key="i.key"
          >
            {{ i.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="contents"
        tag="tbody"
        handle=".handle"
      >
        <b-tr
          v-for="item in contents"
          :key="item.node.id"
          @dragend="updateOrder"
        >
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            <div
              class="d-flex"
              style="align-items: center"
            >
              <b-img
                id="avatar"
                class="mr-2 ml-2"
                rounded="lg"
                :src="buildImageUrl(item)"
                @error="errorImg"
              />
              <p>{{ item.node.name }}</p>
            </div>
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            <span
              v-if="item.node.state == 'GRE'"
              class="text-success"
            >{{ $t('ecommerce.public') }}</span>
            <span
              v-if="item.node.state == 'YEW'"
              class="text-warning"
            >{{ $t('dataGeneric.internal') }}</span>
            <span
              v-if="item.node.state == 'RED'"
              class="text-danger"
            >{{ $t('ecommerce.NoPublic') }}</span>
            <span
              v-if="item.node.state == 'BCK'"
              class="text-dark"
            >{{ $t('ecommerce.NoPublic') }}</span>
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{ fechaFormato(item.node.emisionDate) }}
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{
              fechaFormato(item.node.publishDate)
            }}
          </b-td>

          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{
              fechaFormato(item.node.expirationDate)
            }}
          </b-td>
          <b-td>
            <i
              v-if="manual"
              class="fa fa-align-justify handle cursor-move text-warning"
            />

            <b-dropdown
              v-else
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  color="white"
                  icon="MoreVerticalIcon"
                  size="20"
                />
              </template>
              <b-dropdown-item @click.prevent="dropContentByCategory(item)">
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t("contents.quitContent") }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="showModal(item.node, false)">
                <feather-icon
                  icon="CornerRightUpIcon"
                  size="18"
                />
                {{ $t("contents.moveContent") }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="showModal(item.node, true)">
                <feather-icon
                  icon="FolderPlusIcon"
                  size="18"
                />
                {{ $t('addCategory') }}
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="onRowSelected(item.node.id)">
                <feather-icon
                  icon="Edit2Icon"
                  size="18"
                />
                {{ $t("contents.editContent") }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteContent(item.node.id)">
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t("contents.dropContent") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :exclude-ids="code"
          :type="true"
          :especial="true"
          :columns="categorySelectorTable"
          @confirm-selection="confirm"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BImg,
  BButton,
  BDropdown,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, utf8ToB64, checkRelationOrder, cleanRelationContents, removeRelation, removeResources,
} from '@/store/functions'
import draggable from 'vuedraggable'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import CategorySelector from '../../common/CategorySelector.vue'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BImg,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    draggable,
    BDropdown,
    BDropdownItem,
    BButton,
    CategorySelector,
  },
  props: {
    code: {
      default: '',
    },
    manual: Boolean,
    contenidos: {
      default: () => [],
    },
  },
  data() {
    return {
      contents: [],
      overlay: false,
      userData: getUserData(),
      clickContent: null,
      single: 'single',
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      folders: null,
      addCat: false,
      categoryName: null,
      headers: {},
      categoryId: null,
      currentParentFolder: null,
      perPage: 20,
      currentPage: 1,
      rows: null,
      allFinalCategories: null,
      showSelector: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      showAlert: false,
      selectContent: [],
      infoContent: [],
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('editContent.status') },
        { key: 'emision', label: this.$t('emision') },
        { key: 'date_publication', label: this.$t('contents.datePublication') },
        { key: 'date_final', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
    }
  },
  watch: {
    contenidos() {
      this.contents = this.contenidos.edges
    },
  },
  mounted() {
    this.contents = this.contenidos.edges
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
  },
  methods: {
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    showToast(title, type) {
      if (type === 1) {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title,
            icon: 'CoffeeIcon',
            variant: 'success',
            position: 'bottom-right',
          },
        })
      } else {
        this.$toast.error({
          component: ToastificationContent,
          position: 'bottom-right',
          closeOnClick: true,
          props: {
            title,
            icon: 'fas fa-times',
            variant: 'error',
            position: 'bottom-right',
          },
        })
      }
    },
    onRowSelected(items) {
      this.$router.push({
        name: 'contents-edit',
        params: { id: items },
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    deleteContent(id = null) {
      const { headers } = this
      if (id != null) {
        this.selectContent = []
        this.infoContent = []
        this.selectContent.push(id)
      }
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = '{'
          this.selectContent.forEach((element, i) => {
            query += `cont_${i
              + 1}: allContents(id:"${element}") {
                edges {
                  node {
                    id
                    name
                    contentResources {
                      totalCount
                      edges {
                        node {
                          id              
                          resource {
                            id
                            name
                            file {
                              id
                              name
                            }
                            resourceContent {
                              totalCount
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }      
                  }
                }
              }`
          })
          query += '}'
          axios
            .post('', {
              query,
            }, { headers }).then(res => {
              messageError(res, this)

              const contentResources = res.data.data
              removeResources(contentResources).then(resultado => {
                if (resultado) {
                  this.$swal({
                    title: this.$t('code.title'),
                    text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('code.confirm'),
                    cancelButtonText: this.$t('dataGeneric.cancel'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(response => {
                    if (response.value) {
                      let query2 = 'mutation{'
                      resultado.id.forEach((element, i) => {
                        query2 += `
                                resource_${i + 1}: deleteResource(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                      })
                      if (resultado.file.length) {
                        resultado.file.forEach((element, i) => {
                          query2 += `
                                file_${i + 1}: deleteFile(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                        })
                      }
                      query2 += '}'
                      axios.post('', { query: query2 }).then(r => {
                        messageError(r, this)

                        this.delete()
                      }).catch(err => {
                        console.log(err)
                      })
                    } else {
                      this.delete()
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.delete()
                }
              }).catch(err => {
                console.log(err)
              })
            }).catch(err => {
              console.log(err)
            })
        }
      })
    },
    delete() {
      let query = 'mutation{'
      this.selectContent.forEach((element, i) => {
        query += `a_${i
          + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
      })
      query += '}'
      axios
        .post('', {
          query,
        })
        .then(r => {
          messageError(r, this)

          this.selectContent.forEach(element => {
            // eslint-disable-next-line vue/no-mutating-props
            this.contents = this.contents.filter(
              item => item.node.id !== element,
            )
          })
          this.selectContent = []
        })
        .catch(() => {
        })
    },
    dropContentByCategory(content) {
      const categories = content.node.categories.edges.filter(
        element => element.node.id !== this.code,
      )

      const res = []
      categories.forEach(item => res.push(item.node.id))

      axios
        .post('', {
          query: `
          mutation{
            updateContents(id: "${content.node.id}", input:{
              categories: [${res}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(r => {
          messageError(r, this)

          // eslint-disable-next-line vue/no-mutating-props
          this.contents = this.contents.filter(
            item => item.node.id !== content.node.id,
          )
          /* ELIMINAR RELACIÓN CONTENTORDER */
          try {
            const idRel = content.node.contentContentOrder.edges[0].node.id
            axios
              .post('', {
                query: `
            mutation{
              deleteContentOrder(id:"${idRel}") {
                found
                deletedId
              }
            }
        `,
              })
              .then(result => {
                messageError(result, this)
              })
              .catch(() => { })
            // eslint-disable-next-line no-empty
          } catch (error) { }
        })
        .catch(r => {
          console.log(r)
        })
    },
    showModal(content, boleano) {
      if (content != null) {
        this.selectContent.push(content.id)
        this.infoContent.push(content)
      }
      this.$refs['my-modal'].show()
      this.addCat = boleano
    },

    errorImg(e) {
      e.target.src = fileError
    },

    confirm(data) {
      if (this.addCat) { this.addToCategory(data) } else this.moveToCategory(data)
    },
    moveToCategory(category) {
      this.hideModal()
      this.categoryId = category.node.id
      let query = `query
      {`
      for (let i = 0; i < this.selectContent.length; i += 1) {
        query += `
        q${i + 1}: allContents(id:"${this.selectContent[i]}"){
            edges {
              node {
                id
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          `
      }
      query += `
      }`

      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)

          const deleteFilter = []
          let query2 = ` mutation
      {`
          for (let a = 0; a < this.selectContent.length; a += 1) {
            const data = res.data.data[`q${a + 1}`].edges[0].node
            deleteFilter.push(data.id)

            const categoriasRes = []
            try {
              const categories = data.categories.edges.filter(
                element => element.node.id !== this.code,
              )

              categories.forEach(item => categoriasRes.push(item.node.id))

              categoriasRes.push(this.categoryId)
            } catch (error) {
              console.log(error)

              categoriasRes.push(this.categoryId)
            }

            query2 += `
              m${a + 1}: updateContents(id:"${data.id}", input:{
              categories: [${categoriasRes}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }

                `
          }
          query2 += `
            }`
          axios
            .post('', { query: query2 })
            .then(result => {
              messageError(result, this)

              deleteFilter.forEach(element => {
                // eslint-disable-next-line vue/no-mutating-props
                this.contents = this.contents.filter(
                  item => item.node.id !== element,
                )
              })

              removeRelation(this.code, this.selectContent) // eliminamos relación anterior
              checkRelationOrder(this.categoryId) // Creamos nueva relación
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(res => {
          console.log(res)
        })
    },
    addToCategory(cat) {
      this.categoryId = cat.node.id
      this.categoryName = cat.node.name
      this.hideModal()

      const data = []
      this.selectContent.forEach(element => {
        data.push(element)
      })

      cat.node.categoryContent.edges.forEach(element => {
        data.push(element.node.id)
      })

      const dataArr = new Set(data)

      const result = [...dataArr]

      axios
        .post('', {
          query: `
          mutation{
            updateCategory(id:"${this.categoryId}",input:{categoryContent:[${result}]}){
              category{
                id
                name
                categoryContent(orderBy:"order,-created_at"){
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          `,
        })
        .then(res => {
          messageError(res, this)

          checkRelationOrder(this.categoryId).then(() => {

          }).catch(err => {
            console.log(err)
          }) // Crea una nueva relación

          cleanRelationContents(this.selectContent).then(() => {
          }).catch(err => {
            console.log(err)
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('contentsAddToCat')} ${this.categoryName}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.selectContent = []
          this.infoContent = []
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('error'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    updateOrder() {
      this.changeTypeOrder()
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < this.contents.length; i += 1) {
        arrayId.push(this.contents[i].node.id)
        arrayNum.push(
          this.contents[i].node.contentContentOrder.edges.length !== 0
            ? this.contents[i].node.contentContentOrder.edges[0].node
              .order
            : this.contents[i].node.order,
        )
      }
      const result = ['0']

      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      // Comprobar si arrayId[i] idCategoría existe en relación:

      let query = '{'
      for (let id = 0; id < arrayId.length; id += 1) {
        const code = utf8ToB64(`id:${this.code}`)
        const cont = utf8ToB64(`id:${arrayId[id]}`)
        query += `
        m${id + 1}:  allContentOrder(category:"${code}",content:"${cont}") {
          totalCount
            edges {
              node {
                id
                content {
                  id
                }
                category {
                  id
                }
                order
              }
            }
          }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const editar = []

          for (
            let index = 0;
            index < Object.keys(res.data.data).length;
            index += 1
          ) {
            if (res.data.data[`m${index + 1}`].edges.length !== 0) {
              const idRe = res.data.data[`m${index + 1}`].edges[0].node.id
              const ord = res.data.data[`m${index + 1}`].edges[0].node.order
              const conteOrd = res.data.data[`m${index + 1}`].edges[0].node.content.id

              // Si existe, se comprueba si arrayNum[i] es igual a order, si no lo es se edita, si es igual no
              for (let e = 0; e < arrayId.length; e += 1) {
                if (conteOrd === arrayId[e]) {
                  if (ord === result[e]) {
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  } else {
                    editar.push({ id: idRe, order: result[e] })
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  }
                }
              }
            }
          }

          if (editar.length !== 0) {
            query = 'mutation{'
            for (let a = 0; a < editar.length; a += 1) {
              query += `            
          m${a + 1}: updateContentOrder(id:"${editar[a].id}",input:{order:${editar[a].order
}}){
              contentOrder{
                id
              }
            }`
            }
            query += '}'
            axios
              .post('', {
                query,
              })
              .then(r => {
                messageError(r, this)
              })
              .catch(() => { })
          }

          // Si no existe se crea la relación
          if (arrayId.length !== 0) {
            const code = utf8ToB64(`id:${this.code}`)
            query = ` mutation
            {`
            for (let i = 0; i < result.length; i += 1) {
              const cont = utf8ToB64(`id:${arrayId[i]}`)
              query += `
              m${i + 1}: createContentOrder(input:{ order: ${result[i]
},content:"${cont}",category:"${code}"}){
                          contentOrder{
                            id
                           content {
                              id
                            }
                            category {
                              id
                            }
                            order
                          }
                        }
                      `
            }
            query += `
            }`
            axios
              .post('', {
                query,
              })
              .then(r => {
                messageError(r, this)
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeTypeOrder() {
      axios.post('', {
        query: `
          mutation{
          updateCategory(id:"${this.code}",input:{orderType:MAN})  {
            category {
              id
            }
          }
          }
         `,
      }).then(result => {
        messageError(result, this)
      }).catch(() => {

      })
    },
  },
}
</script>
